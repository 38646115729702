@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("./fonts/style.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: geometric, sans-serif;
    /* cursor: none !important; */
    background: white;
  }
  html.dark {
    background: black;
  }
  html * {
    /* cursor: none !important; */
    text-decoration-skip-ink: none;
  }
  body {
  }
}

@layer components {
  .dont-break-out {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  .fancy-underline {
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: no-repeat;
    background-position: bottom left;
  }
  .img-cover {
    @apply block h-full w-full object-cover;
  }
  .img-abs-cover {
    @apply absolute top-0 right-0 bottom-0 left-0 h-full w-full object-cover;
  }
  .global-swiper .swiper-scrollbar-drag {
    top: -3px;
    height: 4px;
    border-radius: 0;
    background: #2a2a30;
    position: relative;
  }
  .dark .global-swiper .swiper-scrollbar-drag {
    background: white;
  }
  .global-swiper .swiper-slide {
    width: auto;
  }
  .btn-b,
  .btn,
  .h1,
  .h2,
  .h3 {
    text-transform: uppercase;
  }
  .btn-b,
  .btn {
    padding-top: 0.2em;
    text-decoration: underline;
  }
  .container-x {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 100%;
  }
  .container-y {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .h1 {
    font: 20px/130% geometric, sans-serif;
  }
  .h2 {
    font: 15px/160% geometric, sans-serif;
  }
  .h3 {
    font: 12px/160% geometric, sans-serif;
  }
  .btn-b {
    font: 15px/100% geometric, sans-serif;
  }
  .btn {
    font: 12px/100% geometric, sans-serif;
  }
  .txt {
    font: 15px/150% "Open Sans", sans-serif;
  }
  .num {
    font: 12px/100% geometric, sans-serif;
  }
  @screen md {
    .container-x {
      padding-right: 40px;
      padding-left: 40px;
    }
    .container-y {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    .h1 {
      font: 50px/1 geometric, sans-serif;
    }
    .h2 {
      font: 20px/160% geometric, sans-serif;
    }
    .h3 {
      font: 14px/160% geometric, sans-serif;
    }
    .btn-b {
      font: 40px/100% geometric, sans-serif;
    }
    .btn {
      font: 15px/100% geometric, sans-serif;
    }
    .txt {
      font: 20px/150% "Open Sans", sans-serif;
    }
    .num {
      font: 16px/100% geometric, sans-serif;
    }
  }
  @screen 2xl {
    .container-y {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    .h1 {
      font: 70px/1 geometric, sans-serif;
    }
    .h2 {
      font: 25px/160% geometric, sans-serif;
    }
    .h3 {
      font: 15px/160% geometric, sans-serif;
    }
    .btn-b {
      font: 60px/100% geometric, sans-serif;
    }
    .btn {
      font: 20px/160% geometric, sans-serif;
    }
    .txt {
      font: 26px/160% "Open Sans", sans-serif;
    }
    .num {
      font: 20px/100% geometric, sans-serif;
    }
  }
}

@utilities {
}
